<template>
    <code-editor
        v-if="code"
        :filePath="filePath"
        :code="code"
        @save="save"
    />
</template>
<script>
import { TuttiClient } from '@iflb/tutti-client'
import CodeEditor from '@/components/views/CodeEditor'
export default {
    components: {
        CodeEditor
    },
    data() {
        return {
            client: new TuttiClient(true),
            wsdPath: '/ducts/wsd',
            filePath: '',
            code: '',

            params: {},
            loadCode: () => {},
            saveCode: () => {},
        }
    },
    props: ['mode'],
    methods: {
        async save(code) {
            await this.saveCode({ ...this.params, code: code });
        }
    },
    async mounted() {
        await this.client.open(this.wsdPath);
        this.client.invokeOnOpen(async () => {
            const accessToken = window.localStorage.getItem('tuttiConsoleAccessToken');
            let signInSuccess = false;
            if(accessToken) {
                await this.client.resource.signIn({ access_token: accessToken });
                signInSuccess = true;
                switch(this.mode) {
                    case 'template':
                        this.loadCode = this.client.resource.loadTemplateCode;
                        this.saveCode = this.client.resource.saveTemplateCode;
                        this.params = {
                            project_name: this.$route.query.project_name,
                            template_name: this.$route.query.template_name
                        };
                        break;
                    case 'scheme':
                        this.loadCode = this.client.resource.loadProjectSchemeCode;
                        this.saveCode = this.client.resource.saveProjectSchemeCode;
                        this.params = { project_name: this.$route.query.project_name };
                        break;
                    //case 'scheme_condition':
                    //    this.loadCode = this.client.resource.loadTemplateCode;
                    //    this.saveCode = this.client.resource.saveTemplateCode;
                    //    break;
                    default:
                        alert('Invalid editor mode');
                        return;
                }
                try {
                    const { path, code } = await this.loadCode(this.params);
                    this.filePath = path.slice(path.indexOf('projects'));
                    this.code = code;
                } catch {
                    alert('Failed to load the file')
                }
            }
            if(!signInSuccess) {
                alert('You need to sign into Tutti Console first!');
            }
        });
    }
}
</script>
<style>
</style>
