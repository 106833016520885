<template>
    <v-app>
        <v-app-bar
            fixed
            app
            extension-height="36"
            elevation="4"
        >
            <v-select
                hide-details
                outlined
                dense
                v-model="cmOptions.theme"
                :items="themeItems"
                label="Theme"
                class="mx-2"
            />
            <v-text-field
                hide-details
                outlined
                dense
                type="number"
                v-model.number="fontSize"
                suffix="pt"
                class="mx-2"
                label="Font size"
            />
            <v-spacer />
            <v-btn outlined color="indigo" @click="saveCode"><v-icon left>mdi-content-save</v-icon>save</v-btn>
            <template #extension>
                <div class="text-caption font-weight-medium">
                    <v-icon left>mdi-file-document-edit-outline</v-icon>
                    {{ filePath }}
                </div>
            </template>
        </v-app-bar>
        <v-main>
            <codemirror v-if="currentCode" v-model="currentCode" :options="cmOptions" :style="{ '--fontSize': fontSize+'pt' }" />
        </v-main>
        <tutti-snackbar ref="snackbar" />
    </v-app>
</template>
<script>
import TuttiSnackbar from '@/components/ui/TuttiSnackbar'
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/vue/vue';
import 'codemirror/mode/python/python';

const themes = {
    'base16-light': 'base16-light.css',
    'base16-dark': 'base16-dark.css',
    'solarized light': 'solarized.css',
    'paraiso-light': 'paraiso-light.css',
    'paraiso-dark': 'paraiso-dark.css',
    'monokai': 'monokai.css',
    'ambiance': 'ambiance.css',
    'cobalt': 'cobalt.css',
    'rubyblue': 'rubyblue.css',
    'mbo': 'mbo.css',
    'hopscotch': 'hopscotch.css',
    'lesser-dark': 'lesser-dark.css',
}
const firstTheme = Object.keys(themes)[0];

export default {
    components: {
        codemirror,
        TuttiSnackbar
    },
    data() {
        return {
            themeItems: Object.keys(themes),
            cmOptions: {
                tabSize: 4,
                mode: '',
                lineNumbers: true,
                line: true,
                smartIndent: true,
                lineWrapping: true,
                theme: null,
                indentUnit: 4,
                electricChars: false,
                extraKeys: {
                    "Tab": function(cm){
                        cm.replaceSelection("    " , "end");
                    },
                    "Shift-Tab": 'indentLess',
                    "Ctrl-S": () => { this.saveCode(); },
                    "Cmd-S": () => { this.saveCode(); },
                    "Cmd-Enter": () => { this.saveCode(); },
                }
            },
            fontSize: 12,
            currentCode: '',
        }
    },
    props: {
        filePath: {
            type: String,
            default: ''
        },
        language: {
            type: String,
            default: null
        },
        code: {
            type: String,
            default: ''
        },
    },
    methods: {
        async saveCode() {
            this.$emit('save', this.currentCode);
            this.$refs.snackbar.show('black', 'Saving file...', 1000);
        },
    },
    watch: {
        'cmOptions.theme'(key) {
            import(`codemirror/theme/${themes[key]}`);
            window.localStorage.setItem('tuttiTemplateEditorTheme', key);
        },
        'fontSize'(size) {
            window.localStorage.setItem('tuttiTemplateEditorFontSize', size);
        }
    },
    async created() {
        this.currentCode = this.code;
        this.cmOptions.theme = window.localStorage.getItem('tuttiTemplateEditorTheme') || firstTheme;
        this.fontSize = window.localStorage.getItem('tuttiTemplateEditorFontSize') || 12;

        if(window.opener) {
            window.resizeTo(1000, window.screen.availHeight);
        }
        window.onbeforeunload = function() {
            return 'Are you sure you want to leave?';
        }

        let language;
        if(this.language === null) {
            const ext = this.filePath.split('.').slice(-1).pop();
            console.log(this.filePath, ext);
            switch(ext) {
                case 'vue':
                    language = 'vue';
                    break;
                case 'py':
                    language = 'python';
                    break;
                default:
                    language = null;
                    break;
            }
        } else {
            language = this.language;
        }
        console.log(language);
        if(language === 'vue') {
            this.cmOptions.mode = 'text/x-vue';
        } else if(language === 'python') {
            this.cmOptions.mode = 'text/x-python';
        }
    }
  };
</script>
<style>
.CodeMirror {
    height: 100%;
    border: 1px solid #ccc;
    font-size: var(--fontSize);
}
#webpack-dev-server-client-overlay {
    display: none!important;
}
</style>
